<template>
    <div class="partnership-content">
        <div class="partnership-banner">
            <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership-banner@2x.png"/>
            <div class="partnership-banner-text">
                <h3>合作伙伴</h3>
                <p>与多方学术、机构合作，更多名院专家授课</p>
            </div>
        </div>

        <div class="partnership-consociation">
            <plate-title title="学术合作机构" desc="ACADEMIC COOPERATLON INSTITUTION" explain="(排名不分先后)"/>
            <!-- 一直换顺序，就一个个显示了 -->
            <div class="partnership-consociation-list">

                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/1.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/2.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/3.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/4.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/18.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/5.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/6.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/7.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/8.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/9.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/10.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/11.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/12.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/13.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/14.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/15.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/16.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/17.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/19.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/20.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/21.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/22.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/23.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/24.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/25.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/26.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/27.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/28.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/29.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/30.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/31.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/32.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/33.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/34.png'"/></div>
                <div class="partnership-consociation-item"><img :src="'https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/35.png'"/></div>
            </div>
        </div>


        <div class="partnership-enterprise">
            <div class="partnership-enterprise-main">
                <plate-title title="合作企业" desc="COOPERATIVE ENTERPRISES" explain="(排名不分先后)"/>
                <div class="partnership-enterprise-list">
                    <div class="partnership-enterprise-item" v-for="(item,index) in 12" :key="item"><img :src="`https://image.ysxxlm.cn/ysxxlm/pc/static/img/partnership/q${index+1}.png`"/></div>
                </div>
            </div>
        </div>

        <div class="professors">
            <div class="professors-head">
                <plate-title title="公益授课专家" desc="PROFESSORS FOR PUBLIC LECTURE" explain="(排名不分先后)"/>
                <div class="professors-head-nav">
                    <span v-for="(item, index) in specialist" :class="index == specialistIndex?'on':''" @click="specialistIndex = index">{{ item.name }}</span>
                </div>
            </div>
            <div class="professors-list">
                <div class="professors-item" v-for="(item,index) in specialist[specialistIndex].children" :key="index">
                    <h4>{{ item.name }}</h4>
                    <p><span v-for="(arr,i) in item.list" :key="i">{{ arr }}</span></p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import specialist from '@/commit/specialist.json'
    export default {
        data() {
            return {
                specialist: specialist,
                specialistIndex: 0
            }
        }
    }
</script>

<style scoped>

</style>
